import React from 'react';

import getConfig from 'next/config';

import axios, { AxiosError } from 'axios';
import { NextPage, InferGetStaticPropsType, GetStaticPaths, GetStaticProps } from 'next';

import {
  fetchEventSpeakers,
  fetchEventFeaturedSessions,
  fetchPopularSessions,
  fetchPopularPosters,
  fetchPopularSpeakers,
  fetchCommonEventData,
} from 'api/events';
import {
  EventStandardContainer,
  EventStandardContainerData,
} from 'components/EventStandardContainer';
import { EventHome } from 'containers/EventHome';
import { handleAxiosError, IError } from 'utils/handleAxiosError';
import { logger } from 'utils/logger';

const { publicRuntimeConfig } = getConfig();

export type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

const EventPage: NextPage<PageProps> = ({ data, error }) => (
  <EventStandardContainer error={error} data={data} Component={EventHome} />
);

export const getStaticProps: GetStaticProps<
  {
    data?: EventStandardContainerData & {
      stats: MorressierEventStats;
      popularSessions: ResolvedPromise<ReturnType<typeof fetchPopularSessions>>;
      popularSpeakers: { count: number; speakers: EventSpeaker[] } | null;
      popularPresentations: MorressierPresentation[] | null;
      speakersData: ResolvedPromise<ReturnType<typeof fetchEventSpeakers>>;
      featuredSessions: ResolvedPromise<ReturnType<typeof fetchEventFeaturedSessions>>;
    };
    error?: IError;
  },
  { eventId: string }
> = async ctx => {
  const eventId = ctx.params?.eventId as string;

  try {
    const [{ eventInfo, tabsConfig }, speakersData, featuredSessions] = await Promise.all([
      fetchCommonEventData(eventId),
      fetchEventSpeakers(eventId),
      fetchEventFeaturedSessions(eventId),
    ]);

    let popularSessions: MorressierSession[] | null = null;
    let popularSpeakers: { count: number; speakers: EventSpeaker[] } | null = null;
    let popularPresentations: MorressierPresentation[] | null = null;
    const isPostEvent = new Date() > new Date(eventInfo.event.end_date);

    if (isPostEvent) {
      [popularSessions, popularSpeakers, popularPresentations] = await Promise.all([
        fetchPopularSessions(eventId),
        fetchPopularSpeakers(eventId),
        fetchPopularPosters(eventId),
      ]);
    }

    return {
      props: {
        data: {
          ...eventInfo,
          popularSessions,
          popularSpeakers,
          popularPresentations,
          speakersData,
          featuredSessions,
          tabsConfig,
        },
      },
      revalidate: 60,
    };
  } catch (err) {
    const error = handleAxiosError(err as AxiosError);

    return {
      props: { error },
      revalidate: 60,
    };
  }
};

const BASE_URL = `${publicRuntimeConfig.api_base}/api/v1/discovery/organizations`;

const fetchOrgEventsPage = (org_id: string, limit: number, offset: number) =>
  axios.get(`${BASE_URL}/${org_id}/events?limit=${limit}&offset=${offset}`).then(resp => resp.data);

const fetchOrgEvents = async (org_id: string) => {
  const maxErrors = 3;
  let errorsCount = 0;

  const limit = 20;
  let eventsIds: string[] = [];
  let offset = 0;
  let hasItems = true;
  while (hasItems && errorsCount < maxErrors) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const { items } = await fetchOrgEventsPage(org_id, limit, offset);
      eventsIds = [...eventsIds, ...items.map(item => item.event_id)];
      hasItems = items.length > 0;
    } catch (err) {
      logger.log('fetchOrgEvents.err:', err);
      // eslint-disable-next-line no-plusplus
      errorsCount++;
    }
    offset += limit;
  }
  return eventsIds;
};

const _fetchEventsToPrerender = async () => {
  const preOrgs = [
    '5eba80059652df329a983c9a', // ASME
  ];

  let eventIds: string[] = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < preOrgs.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    const orgEvents = await fetchOrgEvents(preOrgs[index]);
    eventIds = [...eventIds, ...orgEvents];
  }
  return eventIds;
};

// eslint-disable-next-line arrow-body-style
export const getStaticPaths: GetStaticPaths = async () => {
  //  const eventIds = await fetchEventsToPrerender();
  //  const paths = eventIds.map(eventId => ({ params: { eventId } }));

  return { paths: [], fallback: 'blocking' };
};

export default EventPage;
